<template>
  <div class="flex flex-col items-end">
    <div
      ref="container"
      class="relative w-full"
    >
      <input
        ref="inputRef"
        v-bind="$attrs"
        v-model="inputValue"
        :name="name"
        type="text"
        class="focus:ring-transparent focus:ring-offset-transparent focus:border-black"
        :class="{ 'border-b-0 rounded-b-none': showList }"
        autocomplete="off"
        @input="emitValue"
        @focusin="focused"
        @keydown.tab="unfocused"
        @keyup="handleKeyUp"
      >

      <div
        v-if="showList"
        ref="suggestionList"
        class="max-h absolute z-50 bg-white w-full
          shadow-md left-0 overflow-y-auto border-x border-black border-b flex flex-col max-h-40"
      >
        <div
          v-for="(suggestion, i) in suggestions"
          :key="suggestion"
          class="p-3 cursor-pointer"
          tabindex="0"
          :class="{ 'bg-gray-300': i === activeItem }"
          :data-test="`suggestion-${i}`"
          @click.prevent="selectSuggestion(i, suggestion)"
          @mouseenter="activeItem = i"
        >
          <p>
            {{ suggestion }}
          </p>
        </div>
      </div>
    </div>

    <span
      v-if="error"
      class="text-red-600 text-sm mr-auto"
    >{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useDebounceFn } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    name: string;
    // modelValue: string | number | null | undefined;
    error?: string;
    suggestionCallback: () => Promise<string[]> | string[];
    initialSuggestions?: () => Promise<string[]> | string[];
  }>(),
  {
    error: undefined,
    initialSuggestions: undefined,
    /* eslint-disable no-null/no-null */
    // modelValue: null,
  },
);

const inputValue = defineModel();

const emit = defineEmits<{
  // (e: 'update:modelValue', value: string | number | null | undefined);
  (e: 'valueSelected', index: number, value: string | number | null | undefined);
}>();

const suggestions = ref([]);
const activeItem = ref(-1);
const inputFocused = ref(false);

const selectNext = () => {
  activeItem.value = (activeItem.value + 1) % suggestions.value.length;
};

const selectPrev = () => {
  activeItem.value = (activeItem.value - 1) < 0 ? suggestions.value.length - 1 : activeItem.value - 1;
};

const selectCurrent = () => {
  // emit('update:modelValue', suggestions.value[activeItem.value]);
  inputValue.value = suggestions.value[activeItem.value];
  emit('valueSelected', activeItem.value, suggestions.value[activeItem.value]);
  inputFocused.value = false;
  suggestions.value = [];
};

const handleKeyUp = (event: KeyboardEvent) => {
  inputFocused.value = true;

  switch (event.keyCode) {
    case 40: // down arrow
      selectNext();
      break;

    case 38: // up arrow
      selectPrev();
      break;

    case 13: // enter
      selectCurrent();
      break;
  }
};

const getSuggestions = useDebounceFn(async () => {
  suggestions.value = await props.suggestionCallback();

  activeItem.value = -1;
}, 500);

const getInitialSuggestions = useDebounceFn(async () => {
  suggestions.value = await props.initialSuggestions();

  activeItem.value = -1;
}, 500);

const emitValue = (e: Event) => {
  // emit('update:modelValue', (e.target as HTMLInputElement).value);
  inputValue.value = (e.target as HTMLInputElement).value;

  getSuggestions();
};

const selectSuggestion = (index: number, value: string) => {
  // emit('update:modelValue', value);
  inputValue.value = value;
  emit('valueSelected', index, value);
  inputFocused.value = false;
};

const container = ref<HTMLDivElement>();
const suggestionList = ref<HTMLDivElement>();

onClickOutside(container, () => {
  inputFocused.value = false;
});

const focused = () => {
  inputFocused.value = true;

  if(inputValue.value) {
    getSuggestions();
  } else if(props.initialSuggestions) {
    getInitialSuggestions();
  }
};

const unfocused = (_e: KeyboardEvent) => {
  inputFocused.value = false;
};

watch(() => inputValue.value, (newValue, oldValue) => {
  if(!oldValue && newValue) {
    suggestions.value = [];
    activeItem.value = -1;
  }
});

const showList = computed(() => {
  return suggestions.value.length && (inputValue.value || props.initialSuggestions) && inputFocused.value;
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped></style>
